jQuery(document).ready(function ($) {
	// Code that uses jQuery's $ can follow here.
	//Маска для ввода
	$.mask.definitions.q = "[1,2,3,4,5,6, 7, 8, 9]",
		//$('input[type="tel"]').mask('+7 (q99) 999-99-99');
		$('input[type="tel"]').mask('+79999999999');

	$.fn.setCursorPosition = function (pos) {
		if ($(this).get(0).setSelectionRange) {
			$(this).get(0).setSelectionRange(pos, pos);
		} else if ($(this).get(0).createTextRange) {
			var range = $(this).get(0).createTextRange();
			range.collapse(true);
			range.moveEnd('character', pos);
			range.moveStart('character', pos);
			range.select();
		}
	};

	$('input[name="phone"]').one('click', function () {
		$(this).setCursorPosition(2);
	});

});





//всплывающая подскака на бутсрапе

var popoverTriggerList, popoverList = (popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))).map((function (e) {
	return new bootstrap.Popover(e)
}));
popoverList = (popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))).map((function (e) {
	return new bootstrap.Popover(e)
}));
$("body").on("click", (function (e) {
	$('[data-bs-toggle="popover"]').each((function () {
		$(this).is(e.target) || 0 !== $(this).has(e.target).length || 0 !== $(".popover").has(e.target).length || $(this).popover("hide")
	}))
}));



$('.scroll-to-top').click(function (e) {
	e.preventDefault();
	$('body, html').animate({
		scrollTop: 0
	}, 500);
	return !1
});

$(window).scroll(function () {
	if ($(this).scrollTop() > 150) {
		$('.headerfixed').addClass('fixed');
		$('.scroll-to-top').addClass('visible rotateIn');
		$('.scroll-to-top').removeClass('rotateOut')
	} else {
		$('.headerfixed').removeClass('fixed');
		$('.scroll-to-top').removeClass('visible rotateIn');
		$('.scroll-to-top').addClass('rotateOut')
	}
});


var swiper12 = new Swiper(".slider-inner", {
	loop: true,
	spaceBetween: 50,
	navigation: {
		nextEl: "._next-slide",
	},
});
var swiper3 = new Swiper(".nav-tab-slider_1", {
	autoHeight: true,
	loop: true,
	navigation: {
		nextEl: ".button-next_1",
	},
});
var swiper4 = new Swiper(".nav-tab-slider_2", {
	autoHeight: true,
	loop: true,
	navigation: {
		nextEl: ".button-next_2",
	},
});
var swiper5 = new Swiper(".nav-tab-slider_3", {
	autoHeight: true,
	loop: true,
	navigation: {
		nextEl: ".button-next_3",
	},
});

$('.slider-nav__item').click(function () {
	swiper12.forEach(element => {
		element.slideTo(1, 10)
	});
})

$(document).ready(function () {
	$('.selectBox').SumoSelect({
		placeholder: '',
		max: 3,
		triggerChangeCombined: false,
		forceCustomRendering: true,
		floatWidth: 200,
	});
});


$(document).on("click", ".rr_star", function () {
	var rate = $(this).data("rating_value");
	$("#rRating").val(rate);
});
$(document).on("mouseenter", ".rr_review_form .rating .star", function () {
	var $this = $(this),
		currentStarWidth = $this.data("current_width"),
		ratingValue = $this.data("rating_value"),
		ratingMessage = $this.data("message");
	$this
		.closest(".rating")
		.find(".stars_current")
		.width(currentStarWidth + "%");
	$this.closest(".rating_wrap").find(".rating_message").text(ratingMessage);
});
$(document).on("mouseleave", ".rr_review_form .rating", function () {
	var $this = $(this),
		dataRating = $this.find(".stars_current").data("rating"),
		ratingMessage = $this.closest(".rating_wrap").find(".rating_message").data("message");
	$this.find(".stars_current").width(dataRating + "%");
	$this.closest(".rating_wrap").find(".rating_message").text(ratingMessage);
});
$(document).on("click", ".rr_review_form .rating .star", function () {
	var $this = $(this),
		currentStarWidth = $this.data("current_width"),
		ratingValue = $this.data("rating_value"),
		ratingMessage = $this.data("message");
	$this.closest(".rating").find(".stars_current").data("rating", currentStarWidth);
	if ($this.closest(".input").find("input[name=RATING]").length) {
		$this.closest(".input").find("input[name=RATING]").val(ratingValue);
	} else {
		$this.closest(".input").find("input[data-sid=RATING]").val(ratingValue);
	}
	$this.closest(".rating_wrap").find(".rating_message").data("message", ratingMessage);
});


$(document).on("click", "#submitReview", function () {
	var name = $("#Name").val();
	var text = $("#ReviewContent").val();
	var rate = $("#rRating").val();
	var my_image_upload = $("#my_image_upload").val();
	if (name == "") {
		$("#Name").addClass("wpcf7-not-valid");
		$(".name-field").addClass("d-block");
	} else {
		$("#Name").removeClass("wpcf7-not-valid");
		$(".name-field").removeClass("d-block");
	}
	if (text == "") {
		$("#ReviewContent").addClass("wpcf7-not-valid");
		$(".name-field2").addClass("d-block");
	} else {
		$("#ReviewContent").removeClass("wpcf7-not-valid");
		$(".name-field2").removeClass("d-block");
	}
	if (name !== "" && text !== "") {
		$.ajax({
			url: "/wp-content/themes/kids/ajaxupload.php",
			type: "POST",
			data: new FormData(document.getElementById("featured_upload")),
			contentType: false,
			cache: false,
			processData: false,
			success: function (data) {
				$(".result").text("Спасибо! Ваш отзыв отправлен на модерацию.");
				$("#submitReview").attr("disabled", true);
			},
		});
	}
});